import React from 'react';
import get from 'lodash.get';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { Spacing, SharedStyles, Container } from '../../kenra-storybook/index';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { isUrlInDomain } from 'helpers/url';
const { StTitle, StPageTitle, StImgDesc, StInnerTextNoIndent } = SharedStyles;

const DiamondTerms = ({ intl, page }) => {
    const { title, text } = page;

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                let id = get(node, 'data.target.sys.id');
                let asset = assetMap.get(id);
                let img = asset.url; //get(node, 'data.target.fields.file["en-US"].url');
                let title = asset.title; //get(node, 'data.target.fields.title["en-US"]');
                let href = asset.description; // get(node, 'data.target.fields.description["en-US"]');

                if (!img) return null;

                if (
                    href &&
                    href.length > 0 &&
                    (!href.substring(1) === '/' ||
                        !href.substring(4) === 'http')
                ) {
                    href = null;
                }

                return (
                    <StImgDesc>
                        <a href={href}>
                            <img src={img} alt={title} />
                            {/* <figcaption>{title}</figcaption> */}
                        </a>
                    </StImgDesc>
                );
            },
            [BLOCKS.PARAGRAPH]: paragraph => {
                let content = get(paragraph, 'content[0].value');
                let type = get(paragraph, 'content[0].marks[0].type');
                let isCode = type === 'code';
                if (isCode) {
                    return (
                        <div
                            style={{
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: 'center',
                                flexDirection: 'row',
                                flex: 1,
                                justifyItems: 'center',
                            }}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    );
                } else if (
                    paragraph &&
                    paragraph.content &&
                    paragraph.content.length > 0
                ) {
                    let paragraphContent = '<p>';
                    paragraph.content.forEach(item => {
                        if (item.nodeType === 'text') {
                            let startDecoration = '';
                            let endDecoration = '';
                            item.marks.forEach(mark => {
                                if (mark.type === 'bold') {
                                    startDecoration += '<b>';
                                    endDecoration = '</b>' + endDecoration;
                                } else if (mark.type === 'italic') {
                                    startDecoration += '<i>';
                                    endDecoration = '</i>' + endDecoration;
                                } else if (mark.type === 'underline') {
                                    startDecoration += '<u>';
                                    endDecoration = '</u>' + endDecoration;
                                }
                            });
                            let itemText = get(item, 'value');
                            itemText = itemText.replace(
                                /(\r\n|\n|\r)/g,
                                '<br />'
                            );
                            paragraphContent +=
                                startDecoration +
                                itemText +
                                endDecoration +
                                ' ';
                        } else if (item.nodeType === 'hyperlink') {
                            let destination = get(item, 'data.uri');
                            if (
                                destination !== null &&
                                destination.startsWith('http')
                            ) {
                                let urlParts = destination.split('/');
                                if (isUrlInDomain(urlParts[2])) {
                                    destination = destination.replace(
                                        /.*\/\/[^\/]*/,
                                        ''
                                    );
                                    var localeUrl = '/' + intl.locale;
                                    if (intl.locale === intl.defaultLocale) {
                                        localeUrl = '';
                                    }

                                    destination = `${localeUrl}${destination}`;
                                }
                            }
                            let value = get(item, 'content[0].value');
                            paragraphContent += `<a href=${destination}><u>${value}</u></a>`;
                        }
                    });
                    paragraphContent += '</p>';
                    return (
                        <div
                            style={{
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: 'center',
                                flexDirection: 'row',
                                flex: 1,
                                justifyItems: 'center',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: paragraphContent,
                            }}
                        />
                    );
                } else {
                    return null;
                }
            },
        },
    };
    const assetMap = new Map();
    const renderOptions = function(links) {
        for (const asset of links) {
            assetMap.set(asset.contentful_id, asset);
        }
        return options;
    };
    return (
        <>
            <Spacing removeSpaceBottom>
                <Container>
                    <StPageTitle>
                        <StTitle>
                            <h1>{title}</h1>
                        </StTitle>
                    </StPageTitle>

                    <StInnerTextNoIndent>
                        {documentToReactComponents(
                            JSON.parse(get(text, 'raw'))
                            // renderOptions(get(text, 'references'))
                        )}
                    </StInnerTextNoIndent>
                </Container>
            </Spacing>
        </>
    );
};

export default injectIntl(DiamondTerms);
